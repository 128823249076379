import React, { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  'pk_test_51J6KRLS9ItCogUW8IDZTLB8E8tNnJxCd9rPOrVbWKWlW2Mc81KQrkWKCTFLEk4HA0rSZ45DLah3SQqrXZ1i0Mcsp00r7hHrgIF';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
